export const sourceData = [
  {
    label: "Facebook",
    value: "facebook"
  },
  {
    label: "Instagram",
    value: "instagram"
  },
  {
    label: "Flyers/posters",
    value: "flyers"
  },
  {
    label: "Presentatie in de les",
    value: "presentation"
  },
  {
    label: "vrienden",
    value: "friends"
  },
  {
    label: "Ik ben oud organisatie-lid",
    value: "old_member"
  },
  {
    label: "Ufora",
    value: "ufora"
  },
  {
    label: "Anders",
    value: "other"
  }
];
