import { Team } from "@/lib/types";
import { cn } from "@/lib/utils";
import { RankMenu } from "./ranking/RankMenu";
import { Text } from "@mantine/core";
import { useSelectedTeam } from "@/lib/context/selectedTeam";

const Card = ({ team, disabled }: { team: Team; disabled?: boolean }) => {
  const handleImgError: React.ReactEventHandler<HTMLImageElement> = (e) => {
    const target = e.target as HTMLImageElement;
    if (target.src != "/images/logo.png") {
      target.src = "/images/logo.png";
      target.style.objectFit = "contain";
    }
  };
  return (
    <div
      className={cn(
        "flex h-72 w-full cursor-pointer select-none overflow-hidden rounded-2xl border shadow transition-all",
        !disabled && "hover:shadow-xl"
      )}
    >
      <div className="top-0 h-full w-full overflow-hidden">
        <img
          src={`/api/teams/${team.id}/photo`}
          onError={handleImgError}
          alt={`Cover photo voor ${team.name}`}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="flex h-full w-full flex-col p-2 text-gray-800">
        <Text c={"skoRed"} className={cn("font-gothic text-5xl font-semibold")}>
          {team.name}
        </Text>
        <p className={"text-sm"}>{team.description}</p>
      </div>
    </div>
  );
};

export const TeamCard = ({
  team,
  disableRankSelection,
  functionRef,
}: {
  team: Team;
  disableRankSelection?: boolean;
  functionRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const { setSelectedTeam } = useSelectedTeam();

  const handleClick = () => {
    setSelectedTeam(team);
    functionRef.current?.scrollIntoView();
  }

  return (
    <RankMenu id={team.id} type="team" disabled={disableRankSelection}>
      <div className="w-full" onClick={handleClick}>
        <Card team={team} disabled={disableRankSelection} />
      </div>
    </RankMenu>
  );
};
