import { useRanking } from "@/lib/context/ranking";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { SollicitationForm } from "../forms/SollicitationForm";
import { RankInfo } from "./RankInfo";

export const SollicitationRanking = () => {
  const { first, second, third } = useRanking();

  const handleSubmit = () => {
    if (!first) {
      notifications.show({
        title: "Fout",
        message: "Selecteer een team of functie op positie 1",
        color: "red"
      });
      return;
    }
    modals.open({
      title: "Jouw gegevens",
      size: "lg",
      children: <SollicitationForm />
    });
  };

  return (
    <div className="space-y-2">
      <p className="whitespace-pre-wrap text-center text-sm font-semibold">Je keuze:</p>
      <RankInfo rank={1} data={first} />
      <RankInfo rank={2} data={second} />
      <RankInfo rank={3} data={third} />
      <p className="text-center text-xs">Klik op een functie om het te verwijderen</p>
      <Button fullWidth onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};
