import { createBrowserRouter } from "react-router-dom";
import { AuthenticatedLayout } from "./components/layout/Authenticated";
import { ProtectedLayout } from "./components/layout/Protected";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { SentryErrorBoundary } from "./components/ErrorBoundary";
import { AuthProvider } from "./lib/context/auth";
import { HomePage } from "./pages";
import { LoginPage } from "./pages/auth/login";
import { NotFoundPage } from "./pages/notFound";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    errorElement: import.meta.env.PROD && <SentryErrorBoundary />,
    children: [
      {
        path: "",
        element: <HomePage />
      },
      {
        path: "join-us",
        async lazy() {
          const { JoinUsPage } = await import("./pages/solliciteer");
          return { Component: JoinUsPage };
        },
        loader: () => {
          return fetch("/api/registrations/open");
        }
      },
      {
        path: "/admin",
        element: (
          <AuthProvider>
            <AuthenticatedLayout />
          </AuthProvider>
        ),
        children: [
          {
            async lazy() {
              const { IndexPage } = await import("./pages/admin/index");
              return {
                Component: IndexPage
              };
            },
            index: true
          },
          {
            path: "",
            element: <ProtectedLayout permission="teams:read" />,
            children: [
              {
                path: "teams",
                async lazy() {
                  const { TeamsAdminPage } = await import("./pages/admin/teams");
                  return {
                    Component: TeamsAdminPage
                  };
                }
              }
            ]
          },
          {
            path: "",
            element: <ProtectedLayout permission="functions:read" />,
            children: [
              {
                path: "functions",
                async lazy() {
                  const { FunctionsAdminPage } = await import("./pages/admin/functions");
                  return {
                    Component: FunctionsAdminPage
                  };
                }
              }
            ]
          },
          {
            path: "",
            element: <ProtectedLayout permission="entries:read" />,
            children: [
              {
                path: "entries",
                async lazy() {
                  const { EntriesAdminPage } = await import("./pages/admin/entries");
                  return {
                    Component: EntriesAdminPage
                  };
                }
              }
            ]
          }
        ]
      },
      {
        path: "login",
        element: <LoginPage />
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ]
  }
]);
