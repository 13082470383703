import { QueryRankData, useRanking } from "@/lib/context/ranking";
import { LoadingSpinner } from "../query/loading";
import { cn } from "@/lib/utils";

export const RankInfo = ({
  rank,
  data,
  isBigger
}: {
  rank: 1 | 2 | 3;
  data?: QueryRankData;
  isBigger?: boolean;
}) => {
  const { setRank } = useRanking();

  return (
    <div className="flex flex-col items-center">
      <p className={cn(!isBigger && "text-sm")}>Voorkeur {rank}</p>
      {data ? (
        <div
          className={cn(
            "flex h-12 cursor-pointer items-center justify-center rounded border-2 text-center",
            isBigger ? "w-72" : "w-56",
            rank === 1 && "border-[#e83e30]",
            rank === 2 && "border-[#0087c2]",
            rank === 3 && "border-[#ffd24f]"
          )}
          onClick={() => {
            setRank(rank, 0);
          }}
        >
          {data.isLoading && <LoadingSpinner />}
          {data.data && (
            <p className={cn("font-semibold", isBigger ? "text-sm" : "text-xs")}>
              {data.toLabel()}
            </p>
          )}
        </div>
      ) : (
        <div
          className={cn(
            "flex h-12 items-center justify-center rounded border-2 border-dashed transition-all",
            isBigger ? "w-72" : "w-56",
            rank === 1 && "border-[#e83e30]",
            rank === 2 && "border-[#0087c2]",
            rank === 3 && "border-[#ffd24f]"
          )}
        >
          <p className={cn("font-semibold", isBigger ? "text-sm" : "text-xs")}>
            Selecteer een team of functie!
          </p>
        </div>
      )}
    </div>
  );
};
