import { CgSpinner } from "react-icons/cg";

export const LoadingSpinner = ({ thing }: { thing?: string }) => {
  return (
    <div className="flex h-full items-center justify-center gap-2">
      <CgSpinner className="h-8 w-8 animate-spin" />
      <p>Loading{thing ? ` ${thing}` : ""}...</p>
    </div>
  );
};
