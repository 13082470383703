import { useSelectedTeam } from "@/lib/context/selectedTeam";
import { ErrorView } from "./query/error";
import { LoadingSpinner } from "./query/loading";
import { useQuery } from "@tanstack/react-query";
import { Function } from "@/lib/types";
import { Text } from "@mantine/core";
import { RankMenu } from "./ranking/RankMenu";
import { RankType } from "@/lib/context/ranking";
import { Fragment } from "react/jsx-runtime";
import { cn } from "@/lib/utils";

const FunctionEntry = ({
  title,
  description,
  disableRankSelection,
  id,
  type
}: {
  title: string;
  description: string;
  disableRankSelection?: boolean;
  id: number;
  type: RankType;
}) => {
  return (
    <div className={cn("last:rounded-xl", !disableRankSelection && "hover:shadow-lg")}>
      <hr className="mb-2" />
      <RankMenu type={type} id={id} disabled={disableRankSelection}>
        <div className="px-4 pb-2">
          <Text component="h2" c="skoRed" className="font-gothic text-3xl font-semibold">
            {title}
          </Text>
          <p className="">{description}</p>
        </div>
      </RankMenu>
    </div>
  );
};

export const FunctionList = ({ disableRankSelection }: { disableRankSelection?: boolean }) => {
  const { selectedTeam } = useSelectedTeam();
  const { data, isLoading, isError, error } = useQuery<Function[]>({
    queryKey: ["teams", selectedTeam?.name, "functions"],
    queryFn: async () => {
      const resp = await fetch(`/api/teams/${selectedTeam?.id}/functions`);
      if (!resp.ok) {
        throw new Error("Failed to fetch functions for team");
      }
      return resp.json();
    },
    enabled: selectedTeam !== null
  });

  if (isError) {
    return <ErrorView error={error} />;
  }

  if (isLoading) {
    return <LoadingSpinner thing="Functies" />;
  }

  return (
    <div className="rounded-xl border shadow">
      <Text
        component="h1"
        c="skoRed"
        className="p-4 pb-0 text-center font-gothic text-4xl font-semibold"
      >
        {selectedTeam?.name}
      </Text>
      {data?.map((f) => (
        <Fragment key={f.id}>
          {f.crew_amount > 0 && (
            <FunctionEntry
              id={f.id}
              type={"crewlid"}
              title={`Crew lead ${f.title}`}
              description={f.crew}
              disableRankSelection={disableRankSelection}
            />
          )}
          {f.coordinator_amount > 0 && (
            <FunctionEntry
              id={f.id}
              type={"coordinator"}
              title={`Coördinator ${f.title}`}
              description={f.coordinator}
              disableRankSelection={disableRankSelection}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};
