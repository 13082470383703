import { useQuery } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Function, Team } from "../types";
import { notifications } from "@mantine/notifications";

export declare type RankType = "team" | "crewlid" | "coordinator";
declare type RankInfo = { type: RankType; id: number };
export declare type RankData = Team | Function;
export declare type QueryRankData = {
  isLoading: boolean;
  data?: RankData;
  toLabel: () => string;
} & RankInfo;

declare type RankingContext = {
  first?: QueryRankData;
  second?: QueryRankData;
  third?: QueryRankData;
  setRank: (rank: 1 | 2 | 3, id: number, type?: RankType) => void;
};

const rankingContext = createContext<RankingContext | undefined>(undefined);

const typeToKey = {
  team: "name",
  crewlid: "title",
  coordinator: "title"
};

const typeToPrefix = {
  team: "Team",
  crewlid: "Crew lead",
  coordinator: "Coördinator"
};

const useRank = () => {
  const [info, setInfo] = useState<RankInfo | null>(null);

  const { data, isLoading } = useQuery<RankData>({
    queryKey: ["rank", info?.type, info?.id],
    queryFn: async () => {
      const resp = await fetch(`/api/${info?.type === "team" ? "teams" : "functions"}/${info?.id}`);
      const data = resp.json();
      return data;
    },
    enabled: info !== null
  });

  const toLabel = () =>
    info
      ? `${typeToPrefix[info.type]} ${data?.[typeToKey[info.type] as keyof RankData]}`
      : "Nothing selected";

  const returnData = (): QueryRankData | undefined => {
    if (!info) {
      return;
    }
    return {
      data,
      isLoading,
      ...info,
      toLabel
    };
  };

  return { setInfo, data, isLoading, ...info, returnData, toLabel };
};

export const RankingProvider = ({ children }: PropsWithChildren) => {
  const first = useRank();
  const second = useRank();
  const third = useRank();

  const changeRanking = (rank: 1 | 2 | 3, id: number, type?: RankType) => {
    let modifier;
    switch (rank) {
      case 1:
        modifier = first;
        break;
      case 2:
        modifier = second;
        break;
      case 3:
        modifier = third;
        break;
    }

    if (id > 0 && !type) {
      throw new Error("A rank type is required with a id higher than 0");
    }

    if ([first, second, third].some((r) => r.data?.id === id)) {
      notifications.show({
        message: "Dit team/functie is al geselecteerd",
        variant: "warning"
      });
      return;
    }

    modifier.setInfo(id > 0 ? { type: type!, id } : null);
  };

  return (
    <rankingContext.Provider
      value={{
        first: first.returnData(),
        second: second.returnData(),
        third: third.returnData(),
        setRank: changeRanking
      }}
    >
      {children}
    </rankingContext.Provider>
  );
};

export const useRanking = () => {
  const context = useContext(rankingContext);
  if (!context) {
    throw new Error("useRanking must be used within a RankingProvider");
  }
  return context;
};
