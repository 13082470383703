import { useEffect } from "react";
import { FaExclamationTriangle } from "react-icons/fa";

export const ErrorView = ({ error }: { error: Error }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="flex w-full flex-col items-center">
      <FaExclamationTriangle className="h-8 w-8 text-orange-500" />
      {error.message}
    </div>
  );
};
