import { RankType, useRanking } from "@/lib/context/ranking";
import { Menu } from "@mantine/core";
import { PropsWithChildren } from "react";
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3 } from "react-icons/tb";

export const RankMenu = ({
  children,
  id,
  type,
  disabled
}: PropsWithChildren<{ id: number; type: RankType; disabled?: boolean }>) => {
  const { setRank } = useRanking();

  if (disabled) {
    return children;
  }

  return (
    <Menu shadow="md" width={200} offset={0}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<TbCircleNumber1 size={16} />} onClick={() => setRank(1, id, type)}>
          Zet op positie 1
        </Menu.Item>
        <Menu.Item leftSection={<TbCircleNumber2 size={16} />} onClick={() => setRank(2, id, type)}>
          Zet op positie 2
        </Menu.Item>
        <Menu.Item leftSection={<TbCircleNumber3 size={16} />} onClick={() => setRank(3, id, type)}>
          Zet op positie 3
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
