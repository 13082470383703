import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa6";
import { PiGlobe } from "react-icons/pi";

const socialMedia = [
  {
    href: "https://www.facebook.com/studentkickoff",
    icon: FaFacebookF
  },
  {
    href: "https://www.instagram.com/studentkickoff",
    icon: FaInstagram
  },
  {
    href: "https://studentkickoff.be",
    icon: PiGlobe
  },
  {
    href: "mailto:pr@studentkickoff.bv",
    icon: FaEnvelope
  }
];

export const SocialMediaBtns = () => {
  return (
    <div className="text-primary/75 flex items-center justify-center gap-4">
      {socialMedia.map((social, i) => (
        <a target={"_blank"} href={social.href} key={i}>
          {social.icon({ size: "1.75rem", className: " hover:text-primary" })}
        </a>
      ))}
    </div>
  );
};
