import { Button, Paper, Text, Title } from "@mantine/core";
import { FaMicrosoft } from "react-icons/fa6";

export const LoginPage = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <Paper shadow="sm" p="xl" className="flex h-80 w-96 flex-col text-center">
        <Title>Login</Title>
        <Text c="gray">Join</Text>
        <a href={"/api/auth/login/microsoftonline"} className="mt-12">
          <Button color="red" size="lg">
            <FaMicrosoft size={"1.7rem"} className="mr-2" />
            Office 365
          </Button>
        </a>
      </Paper>
    </div>
  );
};
