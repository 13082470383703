import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Team } from "../types";

declare type SelectedTeamContext = {
  selectedTeam: Team | null;
  setSelectedTeam: (team: Team | null) => void;
};

const selectedTeamCtx = createContext<SelectedTeamContext>({
  selectedTeam: null,
  setSelectedTeam: () => {}
});

export const SelectedTeamProvider = ({ children }: PropsWithChildren) => {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);

  return (
    <selectedTeamCtx.Provider value={{ selectedTeam, setSelectedTeam }}>
      {children}
    </selectedTeamCtx.Provider>
  );
};

export const useSelectedTeam = () => {
  const context = useContext(selectedTeamCtx);
  if (!context) {
    throw new Error("useSelectedTeam must be used within a SelectedTeamProvider");
  }
  return context;
};
