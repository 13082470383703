import { sourceData } from "@/lib/constants";
import { useRanking } from "@/lib/context/ranking";
import { SollicitationSchema, sollicitationSchema } from "@/lib/schemas";
import {
  Button,
  Checkbox,
  Group,
  NumberInput,
  Select,
  Stack,
  Textarea,
  TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { zodResolver } from "mantine-form-zod-resolver";
import { DatePickerInput } from "@mantine/dates";

export const SollicitationForm = () => {
  const { first, second, third } = useRanking();

  const form = useForm<SollicitationSchema>({
    mode: "uncontrolled",
    validate: zodResolver(sollicitationSchema),
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      student: false,
      dob: new Date(),
      motivations: {
        first: {
          type: first!.type,
          teamId: first!.type === "team" ? first!.id : undefined,
          functionId: first!.type !== "team" ? first!.id : undefined,
          motivation: ""
        },
        second: second
          ? {
              type: second.type,
              teamId: second.type === "team" ? second.id : undefined,
              functionId: second.type !== "team" ? second.id : undefined,
              motivation: ""
            }
          : undefined,
        third: third
          ? {
              type: third.type,
              teamId: third.type === "team" ? third.id : undefined,
              functionId: third.type !== "team" ? third.id : undefined,
              motivation: ""
            }
          : undefined
      },
      source: "",
      eula: false
    }
  });

  const handleSubmit = async (values: SollicitationSchema) => {
    if (!values.student) {
      delete values.studyTime;
    }
    const resp = await fetch("/api/entries", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    });
    if (!resp.ok) {
      notifications.show({
        title: "Formulier niet verzonden",
        message: "Er is iets fout gegaan bij het verzenden van het formulier",
        color: "red"
      });
      console.error("Failed to submit sollicitation");
      return;
    }
    notifications.show({
      title: "Formulier verzonden",
      message: "Je sollicitatie is verzonden, bedankt!",
      color: "green"
    });
    modals.closeAll();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} className="h-full space-y-8">
      <Group grow>
        <TextInput
          label="Voornaam"
          withAsterisk
          key={form.key("firstName")}
          {...form.getInputProps("firstName")}
        />
        <TextInput
          label="Achternaam"
          withAsterisk
          key={form.key("lastName")}
          {...form.getInputProps("lastName")}
        />
      </Group>
      <TextInput
        label="Email"
        withAsterisk
        type="email"
        key={form.key("email")}
        {...form.getInputProps("email")}
      />
      <TextInput
        label="Telefoonnummer"
        withAsterisk
        type="tel"
        key={form.key("phone")}
        {...form.getInputProps("phone")}
      />
      <DatePickerInput
        label="Geboortedatum"
        withAsterisk
        key={form.key("dob")}
        {...form.getInputProps("dob")}
      />
      <Group justify="space-between">
        <Stack gap={"xs"} className="w-[50%]">
          <Checkbox
            label="Student?"
            key={form.key("student")}
            {...form.getInputProps("student", { type: "checkbox" })}
          />
          <p className="break-words text-xs">
            Crew leads zijn altijd welkom, maar om coördinator te worden moet je student zijn
          </p>
        </Stack>
        <NumberInput
          label="Resterende studiejaren"
          description="huidig academiejaar bijgerekend"
          withAsterisk
          disabled={!form.getValues()?.student}
          key={form.key("studyTime")}
          {...form.getInputProps("studyTime")}
        />
      </Group>
      <Select
        label="Hoe ben je bij ons terecht gekomen?"
        withAsterisk
        data={sourceData}
        key={form.key("source")}
        {...form.getInputProps("source")}
      />
      <hr />
      <Textarea
        withAsterisk
        label={`Motivatie voor ${first?.toLabel()}`}
        key={form.key("motivation.first.motivation")}
        {...form.getInputProps("motivations.first.motivation")}
      />
      {second && (
        <Textarea
          withAsterisk
          label={`Motivatie voor ${second?.toLabel()}`}
          key={form.key("motivation.second.motivation")}
          {...form.getInputProps("motivations.second.motivation")}
        />
      )}
      {third && (
        <Textarea
          withAsterisk
          label={`Motivatie voor ${third?.toLabel()}`}
          key={form.key("motivation.third.motivation")}
          {...form.getInputProps("motivations.third.motivation")}
        />
      )}
      <hr />
      <Checkbox
        label="Ik ben er me van bewust dat Student Kick-Off wordt georganiseerd door vrijwilligers, en dat ik solliciteer voor een vrijwillige positie. Er is geen vergoeding verbonden aan het werk."
        key={form.key("eula")}
        {...form.getInputProps("eula", { type: "checkbox" })}
      />
      <Group justify="flex-end" mt="md">
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
};
