import { To } from "react-router-dom";

declare type NavRoute = {
  title: string;
  href: To;
  permission?: string;
  children?: Omit<NavRoute, "children">[];
};

export const navRoutes: NavRoute[] = [
  {
    title: "Teams",
    href: "/admin/teams",
    permission: "teams:read"
  },
  {
    title: "Functions",
    href: "/admin/functions",
    permission: "functions:read"
  },
  {
    title: "Entries",
    href: "/admin/entries",
    permission: "entries:read"
  }
];
