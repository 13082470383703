import { usePermission } from "@/lib/hooks/usePermission";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

export const ProtectedLayout = ({
  permission,
  children
}: PropsWithChildren<{ permission: string }>) => {
  const { isAllowed, isLoading } = usePermission(permission);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!isAllowed) {
    return null;
  }

  return (
    <>
      <Outlet />
      {children}
    </>
  );
};
