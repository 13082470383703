import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <div>
      <h1 className="text-2xl font-semibold">404 - Not Found</h1>
      <p>
        Sorry, the page you are looking for does not exist. Go{" "}
        <Link className="underline" to={"/"}>
          home
        </Link>
      </p>
    </div>
  );
};
